import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { SvgType } from '@rocheglobal/component-library-dot-com';
import { Link } from '../../services/navigation/types';
import { NavigationService } from '../../services';
import { HeaderContext } from '../../contexts/header-context';

export type NavigationItem = {
  url: Link;
  text?: string;
  nested_links?: NavigationItem[];
}

export type NavigationItemProps = NavigationItem & Record<string, any> & {
  icon?: SvgType;
  iconSize?: string;
  level?: number;
  hasRightArrow?: boolean;
  mobile?: boolean;
  tabindex?: number;
}

const getAttributes = (attributes: Record<string, any>) => (
  Object.entries(attributes)
    .filter(([, value]) => (value || value === 0))
    .reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {})
);

const getSiblingNavigationItems = (
  item: HTMLElement | undefined,
): HTMLElement[] => (
  Array.from(
    (item?.closest('roche-header') as any as HTMLElement)?.querySelectorAll('roche-navigation-item'),
  ) as any as HTMLElement[]
);

const closeNavigationItem = (
  item: HTMLElement,
) => (
  item.removeAttribute('is-open')
);

const openNavigationItem = (
  item: HTMLElement,
) => (
  item.setAttribute('is-open', 'true')
);

export const NavigationItem = ({
  url,
  text,
  nested_links: nestedLinks,
  mobile,
  icon,
  tabindex,
  iconSize = '20px',
  level = 1,
  hasRightArrow = false,
  ...props
}: NavigationItemProps) => {
  const headerContext = useContext(HeaderContext);
  const item = useRef<HTMLElement>(null);

  const handleToggle = useCallback(() => {
    if (!item.current) return;

    const siblings: HTMLElement[] = getSiblingNavigationItems(item.current);
    const open = item.current.getAttribute('is-open');

    if (open) {
      closeNavigationItem(item.current);
    } else {
      siblings.forEach((sibling) => closeNavigationItem(sibling));
      openNavigationItem(item.current);
    }
  }, [item]);

  const hasNestedLinks = useMemo(() => (
    nestedLinks && nestedLinks.length > 0
  ), [nestedLinks]);

  const isMobileAccordionItem = useMemo(() => (
    mobile && !hasRightArrow && hasNestedLinks
  ), [mobile, hasRightArrow, hasNestedLinks]);

  const tabIndex = useMemo(() => {
    if (mobile) {
      return tabindex ?? (headerContext.isOffcanvasOpen ? 0 : -1);
    }
    return 0;
  }, [tabindex, headerContext.isOffcanvasOpen]);

  const attributes = useMemo(() => getAttributes({
    level,
    'is-current-page': NavigationService.isCurrentPage(url),
    'is-icon-only': !!icon,
    ...props,
  }), [level, url, props]);

  return (
    <roche-navigation-item ref={item} {...attributes}>

      {isMobileAccordionItem
        ? <span onClick={handleToggle}>
          {icon && <roche-icon icon={icon} height={iconSize} />}
          {text && <span>{text}</span>}
          {!icon && hasNestedLinks && <roche-icon icon="chevron-down" height='24px'/>}
        </span>
        : <a href={NavigationService.getParsedNavigationLink(url)} tabIndex={tabIndex}>
          {icon && <roche-icon icon={icon} height={iconSize} />}
          {text && <span>{text}</span>}
          {hasRightArrow && <roche-icon icon="arrow-right" height="24px" />}
        </a>
      }

      {hasNestedLinks && <div slot="nested-links">
        <ul>
          <li>
            <NavigationItem
              url={url}
              text={mobile ? headerContext.translations.explore : text}
              level={level + 1}
              hasRightArrow />
          </li>
          {nestedLinks?.map((link, index) => (
            <li key={index}>
              <NavigationItem {...link} level={level + 1} />
            </li>
          ))}
        </ul>
      </div>}

    </roche-navigation-item>
  );
};
