import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TranslationService } from '@rocheglobal/component-library-dot-com/src/services/translation/index';
import { StringService } from '@rocheglobal/component-library-dot-com/src/services/string/index';
import { breakpoints } from '@rocheglobal/component-library-dot-com/src/style/base';
import { SvgType } from '@rocheglobal/component-library-dot-com';
import { Navigation } from './navigation';
import { AlternateLanguageLink } from '../../services/dom/types';
import { Link } from '../../services/navigation/types';
import { NavigationItem } from './roche-navigation-item';
import { HeaderContext } from '../../contexts/header-context';

type Navigation = {
  primary_navigation: NavigationItem[];
  secondary_navigation: NavigationItem[];
  global_search_link: Link;
  roche_world_wide_link: Link;
  worldwide_icon: SvgType;
}

interface HeaderProps extends PropsWithChildren {
  home: string;
  navigation: Navigation,
  showNavigation?: boolean;
  isMicrosite?: boolean;
  isFixed?: boolean;
  alternates?: AlternateLanguageLink[];
}

interface HeaderTranslations {
  worldwide: string;
  explore: string;
  [key: string]: string;
}

const initialMobileState = (
  typeof window === 'undefined' ? false : (
    window.innerWidth < breakpoints.large
  )
);

export const RocheHeader = ({
  home,
  navigation,
  alternates,
  showNavigation = true,
  isMicrosite = false,
  isFixed = false,
  children = null,
}: HeaderProps) => {
  const [translations, setTranslations] = useState<HeaderTranslations>({ worldwide: 'worldwide', explore: 'explore' });
  const [mobile, setMobile] = useState(initialMobileState);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);

  const {
    primary_navigation: primaryNavigation,
    secondary_navigation: secondaryNavigation,
    global_search_link: globalSearch,
    roche_world_wide_link: worldwide,
    worldwide_icon: worldwideIcon,
  } = navigation;

  const getTranslations = useCallback(async () => {
    setTranslations({
      worldwide: StringService.capitalize(await TranslationService.translate('worldwide')),
      explore: StringService.capitalize(await TranslationService.translate('explore')),
    });
  }, []);

  useEffect(() => {
    getTranslations();
  }, []);

  useEffect(() => {
    const header = ref.current;
    if (!header) return;

    const observer = new MutationObserver((entries) => {
      const { attributes } = entries.pop().target as HTMLElement;
      setMobile(!!attributes.getNamedItem('is-mobile'));
    });

    observer.observe(header, { attributes: true });

    window.addEventListener('rocheOffcanvasTrigger', ({ detail }: CustomEvent) => {
      const { action, offcanvasId } = detail;

      if (offcanvasId === 'roche-offcanvas-menu') {
        setOffcanvasOpen(action === 'open');
      }
    });

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [ref]);

  const hasAlternateLocales = useMemo(() => (
    alternates && alternates.length > 0
  ), [alternates]);

  return (
    <roche-header
      ref={ref}
      home-url={home}
      is-microsite={isMicrosite}
      fixed={isFixed}>
      <HeaderContext.Provider value={{
        isOffcanvasOpen,
        translations,
      }}>
        {showNavigation && (
          <>
            <roche-navigation slot="primary">
              <ul>
                {primaryNavigation?.map((item, index) => (
                  item.url
                    ? <li key={index}>
                      <NavigationItem
                        key={index}
                        url={item.url}
                        nested_links={item.nested_links}
                        text={item.text}
                        mobile={mobile} />
                    </li>
                    : <li key={index} />
                ))}
              </ul>
            </roche-navigation>

            <roche-navigation slot="secondary">
              <ul>
                {secondaryNavigation?.map((item, index) => (
                  item.url
                    ? <li key={index}>
                      <NavigationItem
                        key={index}
                        url={item.url}
                        nested_links={item.nested_links}
                        text={item.text}
                        mobile={mobile} />
                    </li>
                    : <li key={index} />
                ))}
              </ul>
            </roche-navigation>

            <NavigationItem
              slot="search"
              url={globalSearch}
              icon={mobile ? 'search-mobile' : 'search'}
              iconSize={mobile ? '32px' : '20px'}
              mobile={mobile}
              tabindex={0}
            />

            <NavigationItem
              slot="worldwide"
              url={worldwide}
              icon={worldwideIcon || 'globe'}
              iconSize={mobile ? '24px' : '20px'}
              text={translations.worldwide}
              mobile={mobile}
            />

            {hasAlternateLocales
              && <roche-language-switch
                slot="language-switch"
                layout={mobile ? 'inline-list' : 'dropdown'}
              />
            }

          </>
        )}

        {children}
      </HeaderContext.Provider>

    </roche-header>
  );
};
